let buttonBackToTop = document.getElementById("AppButtonBackToTop");

// When the user scrolls down 20px from the top of the document, show the button
//window.onscroll = function () {
//  scrollFunction();
//};
//
//function scrollFunction() {
//  if (
//    document.body.scrollTop > 20 ||
//    document.documentElement.scrollTop > 20
//  ) {
//    buttonBackToTop.style.display = "block";
//  } else {
//    buttonBackToTop.style.display = "none";
//  }
//}

// When the user clicks on the button, scroll to the top of the document
buttonBackToTop.addEventListener("click", backToTop);

function backToTop(e) {
  e.preventDefault();
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}